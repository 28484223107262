@import '@scss/_module';

.quicklink_item {
  display: flex;
  flex-direction: column;
  min-height: rem(132);
  position: relative;
  color: $codgray;
  height: 100%;
  background: $white;
  border-radius: rem(8);
  border: 1px solid $geyser;
  padding: rem(16) rem(16) rem(20) rem(18);
  font-weight: 600;
  font-size: rem(18);
  line-height: rem(25);
  letter-spacing: rem(0.214286);

  @media (hover: hover) {
    transition: box-shadow $anim-func-base $animspeed-base;

    &:hover {
      box-shadow: 10px 30px 60px rgba(56, 87, 104, 0.15);
    }
  }
}

.quicklink_logo {
  display: block;
  padding-right: rem(60);
  margin-bottom: 1rem;
}

.quicklink_title {
  font-weight: inherit;
  display: block;
  margin-top: auto;
}

.link_copy {
  @include link;
  position: absolute;
  top: rem(16);
  right: rem(64);
  opacity: 1;
}

.icon_copy {
  width: rem(12);
  height: rem(15);

  &::before {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.545 15H2.227C1 15 0 13.949 0 12.656v-7.94c0-1.292 1-2.343 2.227-2.343h5.318c1.228 0 2.228 1.051 2.228 2.344v7.94c0 1.292-1 2.343-2.228 2.343zM2.227 3.545c-.614 0-1.113.526-1.113 1.172v7.94c0 .645.5 1.171 1.113 1.171h5.318c.614 0 1.114-.526 1.114-1.172v-7.94c0-.645-.5-1.171-1.114-1.171H2.227zM12 11.19V2.344C12 1.05 11 0 9.773 0H3.592c-.308 0-.557.262-.557.586 0 .324.25.586.557.586h6.18c.615 0 1.114.526 1.114 1.172v8.847c0 .324.25.586.557.586.308 0 .557-.262.557-.586z' fill='%23#{$link-icon-color}'/%3E%3C/svg%3E");
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.545 15H2.227C1 15 0 13.949 0 12.656v-7.94c0-1.292 1-2.343 2.227-2.343h5.318c1.228 0 2.228 1.051 2.228 2.344v7.94c0 1.292-1 2.343-2.228 2.343zM2.227 3.545c-.614 0-1.113.526-1.113 1.172v7.94c0 .645.5 1.171 1.113 1.171h5.318c.614 0 1.114-.526 1.114-1.172v-7.94c0-.645-.5-1.171-1.114-1.171H2.227zM12 11.19V2.344C12 1.05 11 0 9.773 0H3.592c-.308 0-.557.262-.557.586 0 .324.25.586.557.586h6.18c.615 0 1.114.526 1.114 1.172v8.847c0 .324.25.586.557.586.308 0 .557-.262.557-.586z' fill='%23#{$link-icon-color-hover}'/%3E%3C/svg%3E");
  }
}

.link_edit {
  @include link;
  position: absolute;
  top: rem(16);
  right: rem(18);
  opacity: 1;
}

.icon_edit {
  width: rem(13);
  height: rem(13);

  &::before {
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.338.663a2.262 2.262 0 00-3.2 0L1.355 8.446a.597.597 0 00-.143.23L.03 12.223a.59.59 0 00.747.748l3.546-1.182a.592.592 0 00.23-.143l7.784-7.783a2.262 2.262 0 000-3.2zm-.836 2.364L3.82 10.709l-2.295.766.764-2.292L9.974 1.5a1.08 1.08 0 011.528 1.526z' fill='%23#{$link-icon-color}'/%3E%3C/svg%3E");
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.338.663a2.262 2.262 0 00-3.2 0L1.355 8.446a.597.597 0 00-.143.23L.03 12.223a.59.59 0 00.747.748l3.546-1.182a.592.592 0 00.23-.143l7.784-7.783a2.262 2.262 0 000-3.2zm-.836 2.364L3.82 10.709l-2.295.766.764-2.292L9.974 1.5a1.08 1.08 0 011.528 1.526z' fill='%23#{$link-icon-color-hover}'/%3E%3C/svg%3E");
  }
}
